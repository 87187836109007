<template>
  <div class="fs-section fs-news uu-dai-hot">
      <div class="fs-inr news-inr">

          <div class="fs-title">
              <h1>{{ $t('HotDealPage.HOT DEAL') }}</h1>
          </div>

          <div class="news-list news-spr2">
              <router-link v-for="hotDeal in FeatureHotDeal" class="news-item"
                  :to="$t('Route.HotDeal')+'/'+hotDeal.Slug" :key="hotDeal.Id">
                  <div class="fs-bg cmBg" :style="'background-image:url('+hotDeal.Thumbnail+')'"></div>
                  <div class="fs-txt">
                      <h3 v-html="hotDeal.Title"></h3>
                      <p>{{ $t('HotDealPage.Time application') }}{{hotDeal.Duration}} <br>{{ $t('HotDealPage.Range') }} {{hotDeal.Region}}</p>
                  </div>
              </router-link>
          </div>
          <div class="news-list">
              <router-link class="news-item"
                  v-for="hotDeal in  DataForLayoutHotDeal"
                  :to="$t('Route.HotDeal')+'/'+hotDeal.Slug" :key="hotDeal.Id">
                  <div class="fs-bg cmBg" :style="'background-image:url('+hotDeal.Thumbnail+')'">
                  </div>
                  <div class="fs-txt">
                      <h3 v-html="hotDeal.Title"></h3>
                      <p>{{ $t('HotDealPage.Time application') }} {{hotDeal.Duration}} <br>{{ $t('HotDealPage.Range') }}{{hotDeal.Region}}</p>
                  </div>
              </router-link>
          </div>
          
      </div>
  </div>
</template>

<script>
import { Navigation, Pagination } from 'swiper'
import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'
import useJwt from "@/auth/jwt/useJwt";
import EventBus from "@/global-components";
import { isMobile } from 'mobile-device-detect';

// Import Swiper styles
import 'swiper/swiper-bundle.css'
SwiperCore.use([Navigation, Pagination])
function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    images['assets/images/ve-itel/'+item.replace('./', '')] = r(item);    
  });
  return images;
}
const images = importAll(require.context('@/assets/images/ve-itel/', false, /.(png)$/));


export default {
  components: {
    Swiper, SwiperSlide
  },
  data() {
    return {
      FeatureHotDeal:[],
      DataForLayoutHotDeal:[],
      ConfigData:[],
      videoId:'',
      modalClass:'',
      Slug: '',
    }
  },
  watch: {
    $route(to, from) {
      this.fetch();
    }
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log('slide change');
    };
    return {
      onSwiper,
      onSlideChange,
    };
  },
  mounted(){
    this.Slug = this.$route.meta.Slug;
    this.setAnimation();
    this.fetch();
    var t_ = this;
    EventBus.$on('CHANGE_LANG', function (payLoad) {
      t_.fetch();
    });
  },
  methods: {
    Img(pic){
      return images[pic];
    },
    async fetch(){
      this.loading(true);
      await useJwt.post('getMasterData/FeatureHotDeal',{columnFilters:{Slug:this.Slug},sort:[],page:1,pageSize:1000,lang:this.$l}).then(response =>{
        if(response.data.result.length>0){
          this.FeatureHotDeal = response.data.result;
        }
      }).catch(err=>{
        this.$toast.error('Có lỗi trong khi lấy dữ liệu trang, Quý khách vui lòng thử lại!', {icon:true, closeButton: "button"})
      })
      await useJwt.post('getMasterData/DataForLayoutHotDeal',{columnFilters:{Slug:this.Slug},sort:[],page:1,pageSize:1000,lang:this.$l}).then(response =>{
        if(response.data.result.length>0){
          this.DataForLayoutHotDeal = response.data.result;
        }
      }).catch(err=>{
        this.$toast.error('Có lỗi trong khi lấy dữ liệu trang, Quý khách vui lòng thử lại!', {icon:true, closeButton: "button"})
      })
      this.loading(false);
    },
    openVideo(url){
      this.modalClass = 'modal__open';
      this.videoId = 'https://www.youtube.com/embed/' + url + '?rel=0&amp;autoplay=1&amp;playsinline=1';
    },
    closeVideo(){
      this.videoId='';
      this.modalClass = '';
    },
    setAnimation() {
      const windowH = window.screen.height;
      const headH = document.querySelector('.fs-header').clientHeight;
      [].slice.call(document.querySelectorAll('.fs-banner, .choose-pic, .choose-txt, .pack-txt, .pack-info, .i-news-infos, .video-box, .support-pic, .support-txt, .like-pic, .welcome-inr .fs-txt, .welcome-inr .fs-pic, .why-inr .fs-pic, .item-reseaon, .hand-pic, .hand-txt,.about-item, .block-title, .mission-item, .value-item, .promotion-video-box, .vision-inr, .fs-distributions .fs-inr')).forEach((elm) => {
        const style = window.getComputedStyle(elm, null);
        const elmH = style.getPropertyValue('height');
        const inputT = windowH - elm.getBoundingClientRect().top;
        if (inputT > 0 && inputT < (windowH + parseInt(elmH, 10) - headH / 2)) {
          elm.classList.add('fs-ani');
        } else {
          elm.classList.remove('fs-ani');
        }
      });
    }
  }
}
</script>
